import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Deserialize, IJsonObject, Serialize } from 'dcerialize';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryList, CategoryType } from 'src/models/category';
import { MoodleFrame } from 'src/models/moodle-frame';

import {
  AnalyticsCourse,
  AnalyticsCourseItem,
  Course,
  CourseParametersFilter,
  CourseReduced,
  CourseReducedList
} from '../models/course';
import { CoursePrice } from '../models/price';
import { UserBasicData } from '../models/user';
import { AuthService } from '../shared-components/ng-login/auth/auth.service';
import { ApiService } from './api.service';
import { CategoryService } from './category.service';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  private http = inject(HttpClient);
  private apiService = inject(ApiService);
  private authService = inject(AuthService);
  private categoryService = inject(CategoryService);

  path = '/course';

  constructor() {
    this.path = this.apiService.getApiUrl() + this.path;
    this.isCourseSheet$.subscribe((value) => {
      localStorage.setItem(this.storageKey, JSON.stringify(value));
    });
  }

  /**
   * Used to determine if the resource detail is a course or a course sheet
   */
  storageKey = 'courseData';
  public isCourseSheetSubject = new BehaviorSubject<boolean>(this.getInitialValue());
  isCourseSheet$ = this.isCourseSheetSubject.asObservable();

  addResourceType(value: boolean): void {
    this.isCourseSheetSubject.next(value);
  }

  private getInitialValue(): boolean {
    const storedValue = localStorage.getItem(this.storageKey);

    return storedValue !== null ? JSON.parse(storedValue) : false;
  }

  /**
   * GET all searched courses
   *
   * @returns courseList with the results of the request as an observable
   */
  searchCourses(courseFilter: CourseParametersFilter | undefined): Observable<CourseReducedList> {
    return this.http
      .post<IJsonObject>(
        `${this.path}/search`,
        courseFilter ? Serialize(courseFilter, () => CourseParametersFilter) : ''
      )
      .pipe(map((coursesList) => Deserialize(coursesList, () => CourseReducedList)));
  }

  /**
   * Get a course identified by an id
   *
   * @param courseId - Course id to retrieve
   * @returns element retrieved course identified by courseId
   */
  getCourseById(courseId: number): Observable<Course> {
    return this.http.get<IJsonObject>(`${this.path}/${courseId}`).pipe(map((data) => Deserialize(data, () => Course)));
  }

  /**
   * Get a course identified by its idSimo
   *
   * @param idSimo - idSimo of the course to retrieve
   * @returns element retrieved course identified by idSimo
   */
  getCourseByIdSimo(idSimo: number): Observable<Course> {
    return this.http
      .get<IJsonObject>(`${this.path}/idSimo/${idSimo}`)
      .pipe(map((data) => Deserialize(data, () => Course)));
  }

  /**
   * Get a course sheet identified by its idSeo
   *
   * @param idSeo - Course idSeo to retrieve
   * @returns element retrieved course identified by idSeo
   */
  getCourseByIdSeo(idSeo: string): Observable<Course> {
    return this.http
      .get<IJsonObject>(`${this.path}/idSeo/${idSeo}`)
      .pipe(map((data) => Deserialize(data, () => Course)));
  }

  /**
   * Checks whether the current user is enrolled in the course
   * @returns IsEnrolled
   */
  isEnrolled(courseCode: string): Observable<boolean> {
    return this.http.get<IJsonObject>(`${this.path}/access/${courseCode}`).pipe(map((response) => !!response));
  }

  /**
   * Get mandatory moodle data to build the iframe
   *
   * @param idCourse: number id to retrieve
   */
  public getMoodleData(courseCode: string): Observable<MoodleFrame> {
    return this.http
      .get<IJsonObject>(`${this.path}/${courseCode}/moodle`)
      .pipe(map((jsonRequest) => Deserialize(jsonRequest, () => MoodleFrame)));
  }

  /**
   * Make a request to the API to update the
   * @return CourseSourceFilter
   */
  setMoodelUserIDNumber(courseCode: string, cif: string): Observable<any> {
    return this.http.post(`${this.path}/${courseCode}/${cif}/set-moodle-user-idnumber`, null);
  }

  getCourseFaculties(courseId: number): Observable<CategoryList> {
    return this.http
      .get<IJsonObject>(`${this.path}/${courseId}/faculties`)
      .pipe(map((data) => Deserialize(data, () => CategoryList)));
  }

  courseToAnalytics(course: Course | CourseReduced): Observable<AnalyticsCourse> {
    const user = this.authService.getUserData();
    const userCountry = user.storeLocation;
    let price: CoursePrice | undefined = undefined;
    // Check if course has a price array and find the matching currency
    if (Array.isArray(course.price) && course.price.length > 0) {
      price = course?.price.find((item) => item.country === userCountry);
    }
    // Get categories
    const categoryObservables = course.category.map((category) => {
      if (isNaN(category)) {
        return of({ label: '', type: '' });
      }

      return this.categoryService.getCategoriesByInnovaId(category);
    });

    return forkJoin(categoryObservables).pipe(
      map((categories) => {
        const categoriesList = categories.filter((category) => category.label !== '');
        const firstCategory = categoriesList.find((category) => category.type === CategoryType.TYPES);
        // second element is the next category that is not the first one
        const secondCategory = categoriesList.find((category) => category !== firstCategory);
        // third element is the next category that is not the first one and not the second one
        const thirdCategory = categoriesList.find(
          (category) => category !== firstCategory && category !== secondCategory
        );
        const item = new AnalyticsCourseItem(
          course.name ? course.name.slice(0, 100) : '',
          course.idSimo.toString(),
          price ? price.price : 0,
          price ? price.currency : '',
          course.brand ?? '',
          firstCategory?.label ?? '',
          secondCategory?.label ?? '',
          thirdCategory?.label ?? '',
          course.campaign ?? '',
          1
        );

        return new AnalyticsCourse('', course.brand, price ? price.price : 0, 0, 0, price ? price.currency : '', '', [
          item
        ]);
      })
    );
  }

  requestCourseSheet(courseId: number, userData: UserBasicData): Observable<void> {
    return this.http.post<void>(
      `${this.path}/sheet/${courseId}/request`,
      Serialize(userData, () => UserBasicData)
    );
  }
}
